import React, { FC } from "react";
import { t, Trans } from "@helpers/translate";
import { GetStaticPaths, GetStaticProps } from "next";
import { graphql } from "@helpers/graphql";
import {
  AuthorType,
  City,
  PostOrderBy,
  PostsQuery,
  ReviewsQuery,
  Supplier,
  Tour,
  TourCardFragment,
} from "@graphql/types";

import { CACHE_TIME, PLACEHOLDER_IMAGE } from "@constants";
import { getMetaDataDescription } from "@helpers/getMetaDataDescription";
import LoadingPage from "@components/pages/LoadingPage";
import Head from "@components/common/Head";
import FaqMicroFormat from "@components/common/MicroFormat/FaqMicroFormat";
import ProductMicroFormat from "@components/common/MicroFormat/ProductMicroFormat";
import Hero from "@components/common/Hero/Hero";
import { Container } from "@components/ui/Container";
import { CategoriesLinks } from "@components/pages/SearchPage/CategoriesLinks";
import ToursBlock from "@components/common/ToursBlock";
import { ButtonLink } from "@components/ui/Button";
import SuppliersBlock from "@components/common/SupplierBlock";
import { CitiesBlock } from "@components/common/LocationBlock/CitiesBlock";
import SeoContentBlock from "@components/common/SeoContentBlock";
import FaqContentBlock from "@components/common/Faq/FaqContentBlock";
import { Posts } from "@components/pages/SupplierPage/Posts";
import Stats from "@components/common/Stats";
import CollectionsBlock from "@components/common/CollectionsBlock";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";
import { isInValidCountrySlug } from "@helpers/isInValidCountrySlug";
import { Divider } from "@components/ui/Divider";
import { IsAPIValidationError } from "@helpers/validations/IsAPIValidationError";
import {
  CityFooterData,
  getCityFooterData,
} from "@components/common/SEO/utils/getCityFooterData";
import { SEOCityLinks } from "@components/common/SEO/SEOCityLinks";
import { ROUTES } from "@root/routes";
import Reviews from "@components/common/Reviews";
import ScrollToTop from "@components/ui/ScrollToTop/ScrollToTop";

const getPostsVariables = (
  postOrderType: PostOrderBy,
  countryId?: string,
  cityId?: number,
  ignorePosts?: number[]
) => {
  return {
    first: 4,
    filters: {
      ORDER_BY: postOrderType,
      AUTHOR: [AuthorType.Company],
      IGNORE_POSTS: ignorePosts,
      COUNTRY_ID: countryId,
      CITY_ID: cityId,
    },
  };
};

const getReviewsVariables = (CITY_SLUG?: string) => {
  return {
    first: 3,
    filters: {
      CITY_SLUG: CITY_SLUG,
    },
  };
};

const hasTours = (res?: Tour[]) => {
  return (res?.length || 0) > 0;
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: "blocking",
  };
};

const fetchCityData = async (slug: string) => {
  try {
    const data = await graphql.cityTopPage({ slug });
    return data;
  } catch (e) {
    // If the error is due to validation error, then return null
    if (IsAPIValidationError(e)) {
      return null;
    }

    // other errors should be reported
    // removed: throw new Error(e); as we were not catching the error anywhere
    return null;
  }
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const citySlug = params?.city as string;
  const countrySlug = (params?.country as string).toLocaleLowerCase();

  //1. Get all the data
  const data = await fetchCityData(citySlug);
  const correctCountrySlug = data?.cityTopPage?.city?.country?.slug;

  // Case 1: When city is invalid but country is valid
  if (data === null && isInValidCountrySlug(countrySlug)) {
    return {
      redirect: {
        destination: `/${countrySlug}`,
        statusCode: 301,
      },
    };
  }
  // Case 2: City and country both are invalid
  if (data === null && !isInValidCountrySlug(countrySlug)) {
    return { notFound: true };
  }
  // Case 3: Data is missing (neither city nor country are valid) -- written only for TypeScript
  if (data === null) {
    return { notFound: true };
  }

  // Case 4: Country slug is invalid -- important for SEO
  // /{random_string}/validCity -> /{correctedCountrySlug}/{validCity}
  if (!isInValidCountrySlug(countrySlug)) {
    return {
      redirect: {
        destination: `/${correctCountrySlug}/${citySlug}`,
        statusCode: 301,
      },
    };
  }
  // Case 5: Country slug is valid, but it doesn't match the correct country slug
  // /japan/seoul redirects to /japan (not to /korea/seoul)
  if (isInValidCountrySlug(countrySlug) && countrySlug !== correctCountrySlug) {
    return {
      redirect: {
        destination: `/${countrySlug}`,
        statusCode: 301,
      },
    };
  }

  //Case 6 (Special): handling for Singapore
  const singaporeCityId = 4386;
  if (singaporeCityId === data?.cityTopPage?.city?.id) {
    return {
      redirect: {
        destination: "/singapore",
        statusCode: 301,
      },
    };
  }

  if (
    data.cityTopPage.suppliers.length === 0 &&
    data.cityTopPage.transportationTours.length === 0
  ) {
    return {
      redirect: {
        destination: "/",
        statusCode: 308,
      },
    };
  }

  //2. Get 20 popular articles of country
  const popularCountryArticles = await graphql.posts({
    first: 20,
    random: 4,
    filters: {
      ORDER_BY: PostOrderBy.RankingDesc,
      AUTHOR: [AuthorType.Company],
      IGNORE_POSTS: [],
      COUNTRY_ID: data?.cityTopPage?.city?.country?.id,
    },
  });

  //3. Get reviews
  const reviews = await graphql.reviews(getReviewsVariables(citySlug));

  //4. Get latest articles
  const latestArticles = await graphql.posts(
    getPostsVariables(
      PostOrderBy.CreatedAtDesc,
      data?.cityTopPage?.city?.country?.id,
      data?.cityTopPage?.city?.id
    )
  );

  return {
    props: {
      initialData: {
        city: data?.cityTopPage?.city,
        tours: data?.cityTopPage?.tours,
        newTours: data?.cityTopPage?.newTours,
        onlineTours: data?.cityTopPage?.onlineTours,
        transportationTours: data?.cityTopPage?.transportationTours,
        suppliers: data?.cityTopPage?.suppliers,
        newSuppliers: data?.cityTopPage?.newSuppliers,
        reviews,
        latestArticles,
        popularCountryArticles,
        hasMoreTours: data.cityTopPage.hasMoreTours,
        hasMoreSuppliers: data.cityTopPage.hasMoreSuppliers,
        hasMoreTransportationTours: data.cityTopPage.hasMoreTransportationTours,
        hasMoreNewTours: data.cityTopPage.hasMoreNewTours,
        hasMoreNewSuppliers: data.cityTopPage.hasMoreNewSuppliers,
        footer: await getCityFooterData(data.cityTopPage.city),
      },
    },
    revalidate: CACHE_TIME, //  1 hour cache
  };
};

type Props = {
  initialData: {
    city?: City;
    tours?: Tour[];
    newTours?: Tour[];
    onlineTours?: Tour[];
    transportationTours?: Tour[];
    suppliers?: Supplier[];
    newSuppliers?: Supplier[];
    latestArticles?: PostsQuery;
    popularCountryArticles?: PostsQuery;
    reviews?: ReviewsQuery;
    hasMoreTours: boolean;
    hasMoreSuppliers: boolean;
    hasMoreTransportationTours: boolean;
    hasMoreNewTours: boolean;
    hasMoreNewSuppliers: boolean;
    hasMoreCities: boolean;
    footer: CityFooterData;
  };
};

const Page: FC<Props> = ({ initialData }) => {
  if (!initialData) {
    return <LoadingPage />;
  }

  const city = initialData.city;
  const cityName = city?.name;
  const countryId = city?.country.id;
  const countryName = city?.country.name;
  const countrySlug = city?.country.slug || "";
  const isCountryActive = city?.country?.isActive;

  const noIndexFollow =
    !isCountryActive || countryName === "Togo" ? true : false;

  const toursBlocks = [
    {
      type: "suppliers",
      hasMore: initialData.hasMoreSuppliers,
      title: t({
        id: "city.block.suppliers.title",
        message: `Popular ${cityName} Tour Guides`,
        values: {
          cityName: `${cityName}`,
        },
      }),
      data: initialData.suppliers,
      href: `/s?city=${city?.slug?.toLowerCase()}&t=guides`,
      button: t({
        id: "city.block.suppliers.button",
        message: `View All ${cityName} Tour Guides`,
        values: {
          cityName: `${cityName}`,
        },
      }),
    },
    {
      type: "suppliers",
      hasMore: initialData.hasMoreNewSuppliers,
      title: t({
        id: "city.block.new-guides.title",
        message: `New ${cityName} Tour Guides`,
        values: {
          cityName: `${cityName}`,
        },
      }),
      data: initialData.newSuppliers,
      href: `/s?city=${city?.slug?.toLowerCase()}&t=guides`,
      button: t({
        id: "city.block.suppliers.button",
        message: `View All ${cityName} Tour Guides`,
        values: {
          cityName: `${cityName}`,
        },
      }),
    },
    {
      type: "tours",
      hasMore: initialData.hasMoreTours,
      title: t({
        id: "city.block.tours.title",
        message: `Popular ${cityName} Private Tours`,
        values: {
          cityName: `${cityName}`,
        },
      }),
      data: initialData.tours,
      href: `/s?city=${city?.slug?.toLowerCase()}&t=tours`,
      button: t({
        id: "city.block.tours.button",
        message: `View All ${cityName} Private Tours`,
        values: {
          cityName: `${cityName}`,
        },
      }),
    },
    {
      type: "tours",
      hasMore: initialData.hasMoreNewTours,
      title: t({
        id: "city.block.new-tours.title",
        message: `New ${cityName} Private Tours`,
        values: {
          cityName: `${cityName}`,
        },
      }),
      data: initialData.newTours,
      href: `/s?city=${city?.slug?.toLowerCase()}&t=tours`,
      button: t({
        id: "city.block.tours.button",
        message: `View All ${cityName} Private Tours`,
        values: {
          cityName: `${cityName}`,
        },
      }),
    },
    {
      type: "tours",
      hasMore: initialData.hasMoreTransportationTours,
      title: t({
        id: "city.block.transportation-tours.title",
        message: `Popular ${cityName} Private Cars`,
        values: {
          cityName: `${cityName}`,
        },
      }),
      data: initialData.transportationTours,
      href: `/s?city=${city?.slug?.toLowerCase()}&t=transportation`,
      button: t({
        id: "city.block.transportation-tours.button",
        message: `View All ${cityName} Private Cars`,
        values: {
          cityName: `${cityName}`,
        },
      }),
    },
  ];

  return (
    <>
      <Head
        title={t({
          id: "city.seo.title",
          message: `${cityName} Private Tours & Local Tour Guides`,
          values: {
            cityName: `${cityName}`,
          },
        })}
        description={getMetaDataDescription(
          t({
            id: "city.seo.description",
            message: `Top ${cityName} Tours: See reviews and photos of the best ${cityName} private tours and licensed local guides. Plan a day trip and request an itinerary.`,
            values: {
              cityName: `${cityName}`,
            },
          })
        )}
        openGraph={{
          images: [{ url: city?.picture?.url || "" }],
        }}
        noindex={noIndexFollow}
        nofollow={noIndexFollow}
      />

      {city?.faqs && <FaqMicroFormat items={city.faqs} />}

      <ProductMicroFormat
        productID={`${city?.slug}_city`}
        name={cityName || ""}
        description={t({
          id: "city.seo.description",
          values: { cityName },
        })}
        image={city?.picture?.serpImage || city?.picture?.url}
        reviewCount={initialData.city?.stats.reviewsCount}
      />
      <Hero
        placeholder={PLACEHOLDER_IMAGE}
        src={city?.picture?.url}
        alt="city image"
        title={t({
          id: "city.hero.h1",
          message: `${cityName} Private Tours & Local Tour Guides`,
          values: {
            cityName: `${cityName}`,
          },
        })}
        subtitle={t({
          id: "city.hero.h2",
          message: `Discover Things to Do and Plan a Trip to ${cityName}, ${countryName} with a Local Guide`,
          values: {
            cityName: `${cityName}`,
            countryName: `${countryName}`,
          },
        })}
        breadcrumb={[
          {
            text: city?.country.name || "",
            href: city?.country?.url || "",
          },
          {
            text: city?.name || "",
            href: city?.url || "",
          },
        ]}
        reviewsCount={initialData.city?.stats.reviewsCount}
        reviewsRate={initialData.city?.stats.reviewsRating}
      />

      <div className="max-w-6xl mx-auto md:pt-4">
        {city && (
          <CategoriesLinks
            filters={{ city: city.slug }}
            className="sticky top-0 z-10 px-4 md:px-8 pb-4 pt-4 bg-white shadow-lg-simple md:shadow-none"
            hasGuides={true}
            hasTours={hasTours(initialData.tours)}
            hasOnlineTours={hasTours(initialData.onlineTours)}
            hasTransportation={hasTours(initialData.transportationTours)}
          />
        )}
        <Container className="pt-8 md:pt-0 pb-8 pr-0 md:pr-8">
          <Divider className="mt-4 mb-4 hidden md:flex" />
          {/* Popular Tours by Category */}
          {toursBlocks.map((block, index) => {
            if (block.type === "tours") {
              const data = block.data as Tour[];

              if (!data || !data?.length) {
                return null;
              }

              return (
                <ToursBlock
                  key={`tour-block-${index}`}
                  title={block.title}
                  data={data as TourCardFragment[]}
                  enableSwipeOnMobile={true}
                >
                  {block?.hasMore && (
                    <ButtonLink
                      title="GoWithGuide - ToursBlock"
                      href={getAbsoluteUrl(block.href)}
                      variant="default-outline"
                      className=" max-w-xs sm:max-w-sm md:max-w-full truncate"
                    >
                      {block.button}
                    </ButtonLink>
                  )}
                </ToursBlock>
              );
            }

            if (block.type === "suppliers") {
              const data = block.data as Supplier[];

              if (!data || !data?.length) {
                return null;
              }

              return (
                <SuppliersBlock
                  key={`supplier-block-${index}`}
                  title={block.title}
                  data={data}
                  className="mb-10"
                  enableSwipeOnMobile={true}
                >
                  {block?.hasMore && (
                    <ButtonLink
                      href={getAbsoluteUrl(block.href)}
                      title="GoWithGuide - Supplier block"
                      variant="default-outline"
                      className=" max-w-xs sm:max-w-sm md:max-w-full truncate"
                    >
                      {block.button}
                    </ButtonLink>
                  )}
                </SuppliersBlock>
              );
            }
            return null;
          })}
          {city && city.relatedCities && (
            <CitiesBlock
              title={t({
                id: "city.block.day-trips.title",
                message: `Day Trips from ${cityName}`,
                values: {
                  cityName: `${cityName}`,
                },
              })}
              data={city.relatedCities}
              className="my-10"
              enableSwipeOnMobile={true}
            />
          )}

          {/* Reviews */}
          <div id="reviews" className="pr-4 md:pr-0">
            <Reviews
              type="tours"
              initialData={initialData?.reviews}
              filters={getReviewsVariables(city?.slug)}
              title={t({
                id: "city.block.review.title",
                message: `${cityName} Tour Reviews`,
                values: {
                  cityName: `${cityName}`,
                },
              })}
            />
          </div>

          {/* Seo and Faq */}
          <div className="pr-4 md:pr-0">
            <SeoContentBlock
              content={city?.seoContent.top.content || ""}
              title={city?.seoContent.top.title || ""}
            />

            {city?.faqs && (
              <FaqContentBlock
                title={
                  (city?.name ?? "") +
                  " " +
                  t({ id: "faq.h3", message: "Frequently Asked Questions" })
                }
                items={city.faqs}
              />
            )}
          </div>

          {/* Latest Articles */}
          {cityName &&
            initialData.latestArticles?.posts?.edges &&
            initialData.latestArticles?.posts?.edges.length > 0 && (
              <div className="mt-20 mb-8">
                <Posts
                  showUser={true}
                  initialData={initialData?.latestArticles}
                  countryName={cityName || countryName}
                  filters={getPostsVariables(PostOrderBy.CreatedAtDesc)}
                />

                <div className="flex justify-center">
                  <ButtonLink
                    href={ROUTES.country(city?.country.slug)
                      .city(city?.slug)
                      .blog()}
                    variant="default-outline"
                    rel="noopener noreferrer"
                    title="View More Latest Articles"
                    className=" max-w-xs sm:max-w-sm md:max-w-full truncate"
                  >
                    <Trans
                      id="blogs.view.city.more-new-articles"
                      values={{
                        cityName: `${cityName}`,
                      }}
                    >
                      View More Latest {cityName} Articles
                    </Trans>
                  </ButtonLink>
                </div>
              </div>
            )}

          {/* Show country posts if area posts not exists */}
          {initialData.latestArticles?.posts?.edges &&
            initialData.latestArticles?.posts?.edges.length === 0 &&
            initialData.popularCountryArticles?.posts?.edges &&
            initialData.popularCountryArticles?.posts?.edges.length > 0 && (
              <div className="mt-20 mb-8">
                <Posts
                  showUser={true}
                  initialData={initialData.popularCountryArticles}
                  countryName={countryName}
                  postTitle={t({
                    id: "blogs.popular.country.must-read-articles",
                    message: `${countryName} Must Read Articles`,
                    values: {
                      countryName: `${countryName}`,
                    },
                  })}
                  filters={getPostsVariables(
                    PostOrderBy.RankingDesc,
                    countryId
                  )}
                />

                <div className="flex justify-center">
                  <ButtonLink
                    href={ROUTES.country(countrySlug).blog()}
                    variant="default-outline"
                    rel="noopener noreferrer"
                    title="View More Popular Articles"
                    className=" max-w-xs sm:max-w-sm md:max-w-full truncate"
                  >
                    <Trans
                      id="blogs.view.country.more-popular-articles"
                      values={{
                        countryName: `${countryName}`,
                      }}
                    >
                      View More {countryName} Popular Articles
                    </Trans>
                  </ButtonLink>
                </div>
              </div>
            )}
          <div className="pr-4 md:pr-0">
            <div className="my-10">
              <Stats />
            </div>

            {city && (
              <CollectionsBlock
                location={city}
                hasGuides={true}
                hasTourRequest={true}
                hasOnlineTours={hasTours(initialData.onlineTours)}
                hasTransportation={hasTours(initialData.transportationTours)}
              />
            )}
          </div>

          <SEOCityLinks {...initialData.footer} />

          <ScrollToTop />
        </Container>
      </div>
    </>
  );
};

export default Page;
