import Link from "next/link";
import React, { FC } from "react";
import { t } from "@helpers/translate";
import { Card } from "@components/ui/Card";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";
export interface CollectionCardProps {
  title: string;
  description: string;
  picture: string;
  url: string;
}
const CollectionCard: FC<CollectionCardProps> = (props) => {
  return (
    <Link
      href={getAbsoluteUrl(props.url)}
      title={t({
        id: "common.link.title",
        message: "GoWithGuide - Private Tours & Local Tour Guides",
      })}
    >
      <Card>
        <Card.Image src={props.picture} />
        <Card.Container>
          <Card.Body className="h-auto sm:h-32">
            <Card.Title className="truncate-2" style={{ minHeight: "3.5rem" }}>
              {props.title}
            </Card.Title>
            <Card.Description className="truncate-3 mb-0">
              {props.description}
            </Card.Description>
          </Card.Body>
        </Card.Container>
      </Card>
    </Link>
  );
};
export default CollectionCard;
