import React from "react";
import { Category, City } from "@graphql/types";
import {
  LinksSection,
  LinksSectionGroup,
} from "@components/common/LinksSection";
import { getLocationLinks } from "../utils/getLocationLinks";
import { CityFooterFragment } from "../utils/getCityFooterData";

interface SEOCityLinksProps {
  city: CityFooterFragment;
  cities: City[] | undefined;
  categories: Category[] | undefined;
}
export const SEOCityLinks = ({
  city,
  cities,
  categories,
}: SEOCityLinksProps) => {
  const sections: LinksSection[] = [];

  sections.push({
    title: `Things To Do in ${city?.name}`,
    links: getLocationLinks(undefined, city),
  });

  if (categories && categories.length > 0) {
    sections.push({
      title: `More Tours in ${city.name}`,
      links: categories?.map((category) => ({
        title: category.name,
        url: city.url + "/category/" + category.slug,
      })),
    });
  }

  if (cities && cities.length > 0) {
    sections.push({
      title: `Top ${city.country.name} Destinations`,
      links: cities?.map((city) => ({
        title: city.name,
        url: city.url,
      })),
    });
  }

  return (
    <div className="mt-14">
      <LinksSectionGroup title={`More about ${city.name}`} links={sections} />
    </div>
  );
};
