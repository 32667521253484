import { CityCardFragment, CountryCardFragment } from "@graphql/types";
import { t } from "@helpers/translate";
import React, { FC } from "react";
import CollectionCard, { CollectionCardProps } from "../CollectionCard";

function getGrid(total: number) {
  if (total == 2) {
    return "grid-cols-1 md:grid-cols-2 lg:grid-cols-2";
  }

  if (total == 4) {
    return "grid-cols-1 md:grid-cols-2 lg:grid-cols-4";
  }

  return "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3";
}

interface Props {
  location: CountryCardFragment | CityCardFragment;
  hasOnlineTours: boolean;
  hasGuides: boolean;
  hasTransportation: boolean;
  hasTourRequest: boolean;
}

const CollectionsBlock: FC<Props> = ({
  location,
  hasGuides,
  hasOnlineTours,
  hasTransportation,
  hasTourRequest,
}) => {
  const locationName = location.name;

  //const pictureCDN = `${process.env.NEXT_PUBLIC_DOMAIN}/images/filters:autojpg()/fit-in/1024x1024/filters:quality(80)/gowithguide/assets`;
  // If there is any change in image url or name, we need to generate new url for avif using getAvifImageUrl query in graphql api.
  // Pass the s3 url to the image and it will give the new avif url.
  const onlineTours: CollectionCardProps = {
    title: t({
      id: "collection.virtual-tours.title",
      message: `Explore ${locationName} Through Virtual Tours`,
      values: {
        locationName: `${locationName}`,
      },
    }),
    description: t({
      id: "collection.virtual-tours.description",
      message: `Experience ${locationName} without leaving the comfort of your home through a virtual tour!`,
      values: {
        locationName: `${locationName}`,
      },
    }),
    picture: `${process.env.NEXT_PUBLIC_DOMAIN}/images/S57nn_IKr5jkCrkkbXjaFZg4ZIESlrhEie705XL-y1c/f:avif/plain/gowithguide/assets/collections/online.jpg`,
    url: `${location.url}/online-experiences`,
  };

  const guides: CollectionCardProps = {
    title: t({
      id: "collection.tour-guides.title",
      message: ` Connect with a Tour Guide in ${locationName}`,
      values: {
        locationName: `${locationName}`,
      },
    }),
    description: t({
      id: "collection.tour-guides.description",
      message: `Chat with the best tour guides in ${locationName}. Get a free itinerary and plan your private tour today.`,
      values: {
        locationName: `${locationName}`,
      },
    }),
    picture: `${process.env.NEXT_PUBLIC_DOMAIN}/images/E16ak0NQ2aQn-GY6txdxTOrNMBNSlYnYYG9qb6ETZoQ/f:avif/plain/gowithguide/assets/collections/unity_guides.jpg`,
    url: `${location.url}/guides`,
  };

  const transportation: CollectionCardProps = {
    title: t({
      id: "collection.private-car-tour.title",
      message: `Book a ${locationName} Private Car Tour`,
      values: {
        locationName: `${locationName}`,
      },
    }),
    description: t({
      id: "collection.private-car-tour.description",
      message: `Tour ${locationName} by car or van, or hire a private guide / driver. See reviews and all car tour details.`,
      values: {
        locationName: `${locationName}`,
      },
    }),
    picture: `${process.env.NEXT_PUBLIC_DOMAIN}/images/le09E78ssNlxFnBKH1FLiVCxq1Km_kHhd0cCEH-x0mM/f:avif/plain/gowithguide/assets/collections/cars.png`,
    url: `${location.url}/cars`,
  };

  const request: CollectionCardProps = {
    title: t({
      id: "collection.private-tours.title",
      message: `Book a Customizable ${locationName} Private Tour`,
      values: {
        locationName: `${locationName}`,
      },
    }),
    description: t({
      id: "collection.booking.description",
      message: `Browse ${locationName} tour itineraries by our local professionals and book your favorite ${locationName} tour.`,
      values: {
        locationName: `${locationName}`,
      },
    }),
    picture: location.picture?.url || "",
    url: `${location.url}/tours`,
  };

  const collections: CollectionCardProps[] = [];

  if (hasGuides) {
    collections.push(guides);
  }

  if (hasTourRequest) {
    collections.push(request);
  }
  if (hasTransportation) {
    collections.push(transportation);
  }

  if (hasOnlineTours) {
    collections.push(onlineTours);
  }

  return (
    <div className="mb-20">
      <div className={`grid gap-4 ${getGrid(collections.length)}`}>
        {collections.map((item, key) => (
          <CollectionCard key={key} {...item} />
        ))}
      </div>
    </div>
  );
};

export default CollectionsBlock;
